<div class="content-edit">
  <form [formGroup]="profileForm">
    <div class="text-end">
      <img src="assets/svg/close.svg" alt="" mat-dialog-close>
    </div>
    <div class="d-flex al-center gap mb-4">
      <!-- <div>
        <img src="assets/images/avatar.png" class="rounded-circle" width="88px" height="88px" alt="">
      </div> -->
      <div class="photo d-flex al-center sp-center rounded-circle me-3 image-container"
      [ngStyle]="{'background-image': 'url(' + ( imageUrl ? imageUrl : user?.foto_perfil ) + ')'}" >
        <ng-container *ngIf="!user?.foto_perfil || !imageUrl">
          <span class="fs-1">{{user?.nombres[0]}}</span>
        </ng-container>
      </div>
      <div class="w-100">
        <div>
            <span class="fw-semibold fs-6">{{user?.nombres}} {{user?.apellidos}}</span>
        </div>
        <hr>
        <div>
          <span class="text-purple fw-regular fs-6 pointer" (click)="profile.click()">Editar foto de perfil</span>
          <input type="file" class="d-none" (change)="uploadFile($event)" #profile>
        </div>
      </div>
    </div>
    <div class="mb-4">
      <span class="fs-6 fw-regular">Presentacion:</span>
    </div>
    <div class="mb-5">
      <textarea rows="6" placeholder="Escribe aquí" class="rounded w-100 border-0" formControlName="presentacion"></textarea>
    </div>
    <div class="text-center">
      <button class="d-flex al-center sp-center bg-black w-100 m-auto rounded" [ngClass]="isLoading ? 'disabled':''"
      (click)="updateProfile()">
          <span class="fs-5 fw-medium text-white">Guardar</span>
      </button>
    </div>
  </form>
</div> 
<ng-container *ngIf="loader">
  <app-loader></app-loader>
</ng-container>