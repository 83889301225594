<div class="d-flex flex-column">
  <div class="row m-0">
    <div class="d-flex flex-column flex-sm-row gap">
      <div class="d-flex al-center sp-center reserve-state animation pointer" [ngClass]="optionCurrent == 1 ? 'bg-black':'bg-white'" (click)="changeOption(1)">
        <span class="fs-6" [ngClass]="optionCurrent == 1 ? 'text-white':''">En curso ({{momentsCurrent.length}})</span>
      </div>
      <div class="d-flex al-center sp-center reserve-state animation pointer" [ngClass]="optionCurrent == 2 ? 'bg-black':'bg-white'" (click)="changeOption(2)">
        <span class="fs-6 animation" [ngClass]="optionCurrent == 2 ? 'text-white':''">Agendadas ({{momentsCooming.length}})</span>
      </div>
      <div class="d-flex al-center sp-center reserve-state animation pointer" [ngClass]="optionCurrent == 3 ? 'bg-black':'bg-white'" (click)="changeOption(3)">
        <span class="fs-6 animation" [ngClass]="optionCurrent == 3 ? 'text-white':''">Terminadas ({{momentsFinished.length}})</span>
      </div>
    </div>
  </div>
  <div class="row mt-4 mx-0">
    <ng-container *ngIf="(optionCurrent == 1 ? momentsCurrent :  optionCurrent == 2 ? momentsCooming : momentsFinished).length == 0">
      <div class="col-12">
        <span class="fw-regular fs-6">Por ahora no cuentas con reservas <strong>{{optionCurrent == 1 ? 'en curso' :  
          optionCurrent == 2 ? 'agendadas' : 'terminadas'}}</strong>. Inténtalo mas tarde</span>
      </div>
    </ng-container>
    <div class="col-lg-6 col-xl-6 col-xxl-4 mb-4" *ngFor="let m of optionCurrent == 1 ? momentsCurrent :  optionCurrent == 2 ? momentsCooming : momentsFinished">
      <div class="d-flex flex-column content-moment bg-white">
        <div class="d-flex content-header">
          <div class="w-100">
            <div class="d-flex al-center">
              <div class="content-icon rounded-circle d-flex al-center sp-center">
                <img [src]="'assets/svg/'+ m.linear" width="32" alt="">
              </div>
              <div class="d-flex flex-column ps-3">
                <span class="animation hint fs-7 fw-light" [ngClass]="optionCurrent == 3 ? 'text-black' : optionCurrent == 1 ? 'color-green-1':'text-purple'">
                  Momento {{ optionCurrent == 3 ? 'terminado' : optionCurrent == 1 ? 'en curso': 'en '+(m.cant_dias_restantes + ' días') }} </span>
                <span class="fs-6 fw-semibold">{{m.categoria}}</span>
              </div>
            </div>
          </div>
          <div class="w-100">
            <div class="d-flex al-center">
              <div class="avatar rounded-pill d-flex al-center sp-center">
                <!-- <img src="assets/images/avatar.png" width="64" alt=""> -->
                <span class="fs-1">{{ isOwner ? m.nombres[0] : m.propietario_nom[0]}}</span>
              </div>
              <div class="d-flex flex-column ps-3">
                <span class="fs-7 fw-light">{{isOwner ? 'Inquilino':'Propietario'}}</span>
                <ng-container *ngIf="!isOwner">
                  <span class="fs-6 fw-bold">{{m.propietario_nom.split(' ')[0]}} {{m.propietario_ape.split(' ')[0]}}</span>
                </ng-container>
                <ng-container *ngIf="isOwner">
                  <span class="fs-6 fw-bold">{{m.nombres.split(' ')[0]}} {{m.apellidos.split(' ')[0]}}</span>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <!-- <hr> -->
        <!-- <div>
        </div> -->
        <hr>
        <div class="d-flex flex-column mb-5 mt-3">
          <div class="d-flex mb-3">
            <div class="box-item">
              <span class="fs-6 fw-regular">Fecha</span>
            </div>
            <span class="fs-6 me-3"> : </span>
            <div>
              <span class="fs-6 fw-regular"> {{getDate(m.fecha_inicio)}}</span>
            </div>
          </div>
          <div class="d-flex mb-3">
            <div class="box-item">
              <span class="fs-6 fw-regular">Horario</span>
            </div>
            <span class="fs-6 me-3"> : </span>
            <div>
              <span class="fs-6 fw-regular">{{getHours(m.fecha_inicio, m.fecha_fin)}}</span>
              <span class="fs-6 fw-regular text-purple">{{isPostDay ? '(*Del día siguiente)':''}}</span>
            </div>
          </div>
        </div>
        <div class="d-flex content-actions">
          <div class="bg-black d-flex al-center sp-center action-reserve w-100 pointer" (click)="showDetail(m, optionCurrent)">
            <span class="fs-5 text-white">Detalles</span>
          </div>
          <ng-container *ngIf="optionCurrent != 3">
            <div class="bg-white d-flex al-center sp-center action-reserve w-100 pointer">
              <span class="fs-5 fw-medium">Mensaje</span>
            </div>
          </ng-container>
          <ng-container *ngIf="optionCurrent == 3">
            <div class="bg-white d-flex al-center sp-center action-reserve w-100 pointer" (click)="showQuiz(m)">
              <span class="fs-5 fw-medium">Valorar</span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="loader">
  <app-loader></app-loader>
</ng-container>