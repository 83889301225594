<div class="d-flex flex-column sp-center h-100 content-information">
  <div class="h-100 w-100" [ngClass]="isRegister() ? 'content-form':'content-form-full'">
    <form [formGroup]="registerForm">
      <div class="d-flex flex-column step-3">
        <div class="row mx-0">
          <div class="col-sm-12">
            <span class="fw-bold" [ngClass]="showInputPassword ? 'fs-1':'fs-2'">Datos generales</span>
          </div>
          <div class="mt-3" [ngClass]="isRegister() ? 'col-sm-12':'col-md-6'">
            <span class="title">Nombres</span>
            <input nz-input formControlName="nombre" placeholder="Escribe tus nombres"
              class="shadow-sm rounded fs-6 bg-white" />
          </div>
          <div class="mt-3" [ngClass]="isRegister() ? 'col-sm-12':'col-md-6'">
            <span class="title">Apellidos</span>
            <input nz-input formControlName="apellido" placeholder="Escribe tus apellidos"
              class="shadow-sm rounded fs-6 bg-white" />
          </div>
          <div class="mt-3" [ngClass]="isRegister() ? 'col-sm-12':'col-md-6'">
            <span class="title">Celular</span>
            <div class="d-flex">
              <div class="country border shadow-sm me-2 bg-white rounded d-flex al-center sp-center">
                <img src="assets/images/peru.png" class="me-3 w-25" alt="">
                <span class="fs-6">+51</span>
              </div>
              <input nz-input formControlName="celular" maxlength="9" placeholder="Escribe número celular"
                class="shadow-sm rounded fs-6 bg-white" />
            </div>
          </div>
          <div class="mt-3" [ngClass]="isRegister() ? 'col-sm-12':'col-md-6'">
            <span class="title">Fecha de nacimiento</span>
            <div class="d-flex gap">
              <nz-select class="w-100 border-0 fs-6 shadow-sm bg-white" nzPlaceHolder="Día" formControlName="dia">
                <nz-option [nzValue]="d" [nzLabel]="d" *ngFor="let d of days"></nz-option>
              </nz-select>
              <nz-select class="w-100 border-0 fs-6 shadow-sm bg-white" nzPlaceHolder="Mes" formControlName="mes">
                <nz-option [nzValue]="m.id" [nzLabel]="m.name" *ngFor="let m of months"></nz-option>
              </nz-select>
              <nz-select class="w-100 border-0 fs-6 shadow-sm bg-white" nzPlaceHolder="Año" formControlName="anio">
                <nz-option [nzValue]="y" [nzLabel]="y" *ngFor="let y of years"></nz-option>
              </nz-select>
            </div>
          </div>
          <ng-container *ngIf="showInputPassword">
            <div class="mt-3" [ngClass]="isRegister() ? 'col-sm-12':'col-md-6'">
              <span class="title">Contraseña</span>
              <nz-input-group [nzSuffix]="suffixTemplate" class="password d-flex al-center shadow-sm">
                <input
                  [type]="hide ? 'text' : 'password'"
                  nz-input class="fs-6"
                  placeholder="Ingresa una contraseña"
                  formControlName="passwordCurrent"
                />
              </nz-input-group>
              <ng-template #suffixTemplate>
                <span
                  nz-icon
                  [nzType]="hide ? 'eye-invisible' : 'eye'"
                  (click)="hide = !hide" class="fs-4 pointer"
                ></span>
              </ng-template>
              <!-- <div class="d-flex">
                <input nz-input type="password" formControlName="contrasenia"
                placeholder="Ingresa una contraseña" class="border-0 rounded fs-6 px-0 bg-white" />
                <img src="assets/svg/hide.svg" alt="hide.svg">
              </div> -->
            </div>
          </ng-container>
          <ng-container *ngIf="!showInputPassword && showAddres">
            <div class="mt-3" [ngClass]="isRegister() ? 'col-sm-12':'col-md-6'">
              <span class="title">País</span>
              <input nz-input formControlName="country" placeholder="Ingrese su país"
                class="shadow-sm rounded fs-6 bg-white" />
            </div>
            <div class="mt-3" [ngClass]="isRegister() ? 'col-sm-12':'col-md-6'">
              <span class="title">Dirección</span>
              <input nz-input formControlName="direccion" placeholder="Ingrese su dirección"
                class="shadow-sm rounded fs-6 bg-white" />
            </div>
          </ng-container>
          <div class="mt-5" [ngClass]="isRegister() ? 'col-sm-12':'col-md-6'">
            <button class="w-100 btn-medium border-0 animation rounded shadow-sm"
              [ngClass]="{'bg-dark': !showInputPassword,'bg-orange': showInputPassword,'disabled': !registerForm.valid}" (click)="register()">
              <span class="fs-4 text-white">{{ showInputPassword ? 'Empezar':'Guardar cambios'}}</span>
            </button>
          </div>
          <ng-container *ngIf="showInputPassword">
            <div class="text-center fs-6 mt-3" [ngClass]="isRegister() ? 'col-sm-12':'col-md-6'">
              <span class="text-secondary fs-6 fw-regular">¿Ya tienes una cuenta en Espacios y Locaciones Multipropósitos SAC? </span>
              <span style="color: #3DB8AD;" class="pointer fw-medium fs-6 text-orange" routerLink="/login">Iniciar
                sesión</span>
            </div>
          </ng-container>
        </div>
        <!-- <div>
          <div class="row mx-0">
            <div class="col-sm-12">
              <span class="title">Nombres</span>
            </div>
          </div>
          <div class="row mx-0 mt-2">
            <div class="col-sm-12">
              <input nz-input formControlName="nombre" placeholder="Escribe tus nombres"
                class="shadow-sm rounded fs-6 bg-white" />
            </div>
          </div>
        </div> -->
        <!-- <div>
          <div class="row mx-0">
            <div class="col-sm-12">
              <span class="title">Apellidos</span>
            </div>
          </div>
          <div class="row mx-0 mt-2">
            <div class="col-sm-12">
              <input nz-input formControlName="apellido" placeholder="Escribe tus apellidos"
                class="shadow-sm rounded fs-6 bg-white" />
            </div>
          </div>
        </div>
        <div>
          <div class="row mx-0">
            <div class="col-sm-12">
              <span class="title">Celular</span>
            </div>
          </div>
          <div class="row mx-0 mt-2">
            <div class="col-sm-12 d-flex">
              <div class="country border shadow-sm me-2 bg-white rounded d-flex al-center sp-center">
                <img src="assets/images/peru.png" class="me-3 w-25" alt="">
                <span class="fs-6">+51</span>
              </div>
              <input nz-input formControlName="celular" maxlength="9" placeholder="Escribe número celular"
                class="shadow-sm rounded fs-6 bg-white" />
            </div>
          </div>
        </div>
        <div>
          <div class="row mx-0">
            <div class="col-sm-12">
              <span class="title">Fecha de nacimiento</span>
            </div>
          </div>
          <div class="row mx-0 mt-2">
            <div class="col-sm-4">
              <nz-select class="w-100 border-0 fs-6 shadow-sm bg-white" nzPlaceHolder="Día" formControlName="dia">
                <nz-option [nzValue]="d" [nzLabel]="d" *ngFor="let d of days"></nz-option>
              </nz-select>
            </div>
            <div class="col-sm-4 px-0">
              <nz-select class="w-100 border-0 fs-6 shadow-sm bg-white" nzPlaceHolder="Mes" formControlName="mes">
                <nz-option [nzValue]="m.id" [nzLabel]="m.name" *ngFor="let m of months"></nz-option>
              </nz-select>
            </div>
            <div class="col-sm-4">
              <nz-select class="w-100 border-0 fs-6 shadow-sm bg-white" nzPlaceHolder="Año" formControlName="anio">
                <nz-option [nzValue]="y" [nzLabel]="y" *ngFor="let y of years"></nz-option>
              </nz-select>
            </div>
          </div>
        </div>
        <ng-container *ngIf="showInputPassword">
          <div>
            <div class="row mx-0">
              <div class="col-sm-12">
                <span class="title">Contraseña</span>
              </div>
            </div>
            <div class="row mx-0 mt-2 password">
              <div class="col-sm-12 d-flex bg-white rounded shadow-sm border">
                <input nz-input type="password" formControlName="contrasenia"
                  placeholder="Ingresa una contraseña" class="border-0 rounded fs-6 px-0 bg-white" />
                <img src="assets/svg/hide.svg" alt="hide.svg">
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!showInputPassword">
          <div>
            <div class="row mx-0">
              <div class="col-sm-12">
                <span class="title">País</span>
              </div>
            </div>
            <div class="row mx-0 mt-2">
              <div class="col-sm-12">
                <input nz-input formControlName="country" placeholder="Ingrese su país"
                  class="shadow-sm rounded fs-6 bg-white" />
              </div>
            </div>
          </div>
          <div>
            <div class="row mx-0">
              <div class="col-sm-12">
                <span class="title">Dirección</span>
              </div>
            </div>
            <div class="row mx-0 mt-2">
              <div class="col-sm-12">
                <input nz-input formControlName="direccion" placeholder="Ingrese su dirección"
                  class="shadow-sm rounded fs-6 bg-white" />
              </div>
            </div>
          </div>
        </ng-container>
        <hr>
        <div class="row mx-0">
          <div class="col-sm-12">
            <button class="w-100 btn-medium border-0 animation rounded shadow-sm"
              [ngClass]="showInputPassword ? 'bg-orange':'bg-dark'" (click)="register()">
              <span class="fs-4 text-white">{{ showInputPassword ? 'Empezar':'Guardar cambios'}}</span>
            </button>
          </div>
        </div>
        <ng-container *ngIf="showInputPassword">
          <div class="row mx-0">
            <div class="col-sm-12 text-center fs-6">
              <span class="text-secondary fs-6 fw-regular">¿Ya tienes una cuenta en Espacios y Locaciones Multipropósitos SAC? </span>
              <span style="color: #3DB8AD;" class="pointer fw-medium fs-6 text-orange" routerLink="/login">Iniciar
                sesión</span>
            </div>
          </div>
        </ng-container>-->
      </div> 
    </form>
  </div>
</div>
<ng-container *ngIf="loader">
  <app-loader></app-loader>
</ng-container>