<div class="d-flex al-center sp-between content-page">
  <div>
    <img src="assets/svg/w2m-color.svg" (click)="goTo()" class="pointer" width="283" alt="">
  </div>
  <div class="d-flex al-center">
    <ng-container *ngIf="!usuario">
      <div class="d-flex bg-orange content-button pointer sp-center al-center w-100" routerLink="/login">
        <span class="text-white fs-5">Iniciar sesión</span>
      </div>
    </ng-container>
    <ng-container *ngIf="usuario">
      <div class="pointer me-3" (click)="validateOwner()">
        <span class="fs-5 fw-bold susbcribe">Inscribe tu espacio</span>
      </div>
      <div class="avatar bg-white ms-3 d-flex al-center sp-end pointer px-3" nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomRight">
        <span class="fs-5 fw-bold">{{usuario?.nombres.split(' ')[0]}} {{usuario?.apellidos.split(' ')[0]}}</span>
        <div class="profile rounded-circle d-flex al-center sp-center image-container"
        [ngStyle]="{'background-image': 'url(' + usuario?.foto_perfil + ')'}">
          <ng-container *ngIf="!usuario?.foto_perfil">
            <span class="fs-1">{{usuario?.nombres[0]}}</span>
          </ng-container>
        </div>
        <img src="assets/svg/menu.svg" alt="">
      </div>
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
          <li nz-menu-item class="fs-5" routerLink="/reserved-moments">Momentos reservados</li>
          <li nz-menu-item class="fs-5" routerLink="/favorites">Favoritos</li>
          <li nz-menu-item class="fs-5" routerLink="/messages">Mensajes</li>
          <li nz-menu-item class="fs-5" routerLink="/profile-owner">Mi cuenta</li>
          <li nz-menu-item class="fs-5" routerLink="/moments">Modo propietario</li>
          <li nz-menu-item class="fs-5" (click)="logout()">Cerrar sesión</li>
        </ul>
      </nz-dropdown-menu>
    </ng-container>
  </div>
</div>
<ng-container *ngIf="loader">
  <app-loader></app-loader>
</ng-container>